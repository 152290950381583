import React, { useState, useMemo, useEffect } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Drawer from "@mui/material/Drawer";
// Optimized @mui/icons-material imports
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import Insights from "@mui/icons-material/Insights";
import EmojiObjects from "@mui/icons-material/EmojiObjects";
import Science from "@mui/icons-material/Science";
import SmartToy from "@mui/icons-material/SmartToy";
import Speed from "@mui/icons-material/Speed";
import Equalizer from "@mui/icons-material/Equalizer";
import GroupAdd from "@mui/icons-material/GroupAdd";
import HelpOutline from "@mui/icons-material/HelpOutline";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { tokens } from "../../theme";
import { useAuth } from "../../components/context/AuthContext";
import { doc, onSnapshot} from "firebase/firestore";
import {getFirestoreDb } from "../../components/config/firebase";
const SidebarComponent = React.memo(
  ({ isSidebarOpen, handleSidebarToggle, isMobile }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const location = useLocation();
    const { role } = useAuth();
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const { googleData, logout} = useAuth();

    const [expanded, setExpanded] = useState(null); // Tracks which section is open

    const toggleSection = (section) => {
      setExpanded((prev) => (prev === section ? null : section)); // Toggle current or switch sections
    };

    // Memoized styles
    const listItemStyles = useMemo(
      () => ({
        backgroundColor: "transparent",
        "&:hover": { backgroundColor: colors.primary[500] },
        borderRadius: "8px",
        marginBottom: "8px",
        fontSize: "12px",
        padding: "8px 12px",
        width: "100%",
        boxSizing: "border-box", // Ensures the padding doesn't cause overflow
      }),
      [colors.primary]
    );

    const profileBoxStyles = useMemo(
      () => ({
        flexShrink: 0,
        backgroundColor: "#1E2846",
        padding: "20px",
        borderRadius: "10px",
        textAlign: "center",
        width: "100%",
        height: "250px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative", // Add position relative to the Box
      }),
      []
    );

    // Memoized menu lists
    const prizepicksMenu = useMemo(
      () => [
        {
          name: "Projections",
          icon: <Insights />,
          path: "/prizepicks/projections",
        },
        {
          name: "Smart Picks",
          icon: <EmojiObjects />,
          path: "/prizepicks/smartpicks",
        },
        {
          name: "Advanced Picks",
          icon: <Science />,
          path: "/prizepicks/advanced",
        },
        { name: "BOTS", icon: <SmartToy />, path: "/prizepicks/bots" },
      ],
      []
    );

    const performanceMenu = useMemo(
      () => [
        {
          name: "Model Performance",
          icon: <Speed />,
          path: "/model/performance",
        },
        {
          name: "Bots Performance",
          icon: <Equalizer />,
          path: "/bot/performance",
        },
      ],
      []
    );

    const extraMenu = useMemo(
      () => [
        ...(role === "admin"
          ? []
          : [
              { name: "Referral", icon: <GroupAdd />, path: "/referral" },
              { name: "FAQ", icon: <HelpOutline />, path: "/faq" },
            ]),

        ...(role === "admin"
          ? [
              {
                name: "Create User",
                icon: <PersonAddIcon />,
                path: "/createuser",
              },
              { name: "Invoices", icon: <ReceiptIcon />, path: "/invoices" },
              {
                name: "User Management",
                icon: <ManageAccountsIcon />,
                path: "/usermanagement",
              },
            ]
          : []),
      ],
      [role]
    );
    useEffect(() => {
      let unsubscribe;
  
      const fetchProfileImage = async () => {
        try {
          // Step 1: Check sessionStorage for cached profile image URL
          const cachedUrl = sessionStorage.getItem("profileImageUrl");
          if (cachedUrl) {
            setProfileImageUrl(cachedUrl); // Use cached URL
          }
  
          // Step 2: Fetch Firestore instance
          const firestore = await getFirestoreDb();
  
          // Step 3: Reference the user's document in Firestore
          const userDocRef = doc(firestore, "users", googleData?.uid);
  
          // Step 4: Listen for real-time updates
          unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
              const data = docSnapshot.data();
              const firestoreImageUrl = data.profileImageUrl || null;
  
              // Step 5: Update sessionStorage and state if the URL has changed
              if (firestoreImageUrl && firestoreImageUrl !== cachedUrl) {
                sessionStorage.setItem("profileImageUrl", firestoreImageUrl);
                setProfileImageUrl(firestoreImageUrl);
              }
            } else {
              console.warn("User document does not exist in Firestore.");
            }
          });
        } catch (error) {
          console.error("Error fetching profile image from Firestore:", error);
        }
      };
  
      if (googleData?.uid) {
        fetchProfileImage();
      }
  
      // Step 6: Cleanup the Firestore listener on component unmount
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }, [googleData?.uid]);
    const SidebarContent = () => (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        {/* Profile Section */}
        <Box sx={profileBoxStyles}>
          <ModeEditIcon
            onClick={() => navigate("/editprofile")}
            sx={{
              position: "absolute", // Position it absolutely
              top: "10px", // Space from the bottom
              right: "10px", // Space from the right
              cursor: "pointer", // Optionally add a cursor pointer for better UX
            }}
          />
          <Avatar
            alt="profile-user"
            src={`${profileImageUrl? profileImageUrl : googleData?.photoURL}`}
            sx={{ width: 150, height: 150, marginBottom: "10px" }}
          />
          <Typography
            variant="h6"
            sx={{ mt: 1, color: "#FFFFFF", fontSize: "14px" }}
          >
            {googleData?.fullName || "User"}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#34D399", fontSize: "12px" }}
          >
            {secureLocalStorage.getItem("st") || "Member"}
          </Typography>
        </Box>

        {/* Scrollable Options Section */}
        <Box
          sx={{
            flex: 1,
            overflow: "auto",
            marginTop: "10px",
            paddingX: "10px",
            backgroundColor: "#1E2846",
            borderRadius: "10px",
            marginBottom: "10px",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": { width: "6px" },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#6B7280",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#4B5563" },
            "&::-webkit-scrollbar-track": { backgroundColor: "#374151" },
          }}
        >
          <List>
            <ListItem
              button="true"
              onClick={() =>
                navigate(role === "admin" ? "/admin/dashboard" : "/dashboard")
              }
              selected={
                (location.pathname === role) === "admin"
                  ? "/admin/dashboard"
                  : "/dashboard"
              }
              sx={{
                ...listItemStyles,
                backgroundColor:
                  location.pathname === "/dashboard"
                    ? colors.primary[300]
                    : listItemStyles.backgroundColor,
              }}
            >
              <ListItemIcon>
                <HomeOutlined />
              </ListItemIcon>
              <ListItemText
                primary={role === "admin" ? "Admin Dashboard" : "Dashboard"}
              />
            </ListItem>

            {/* PrizePicks Section */}
            <ListItem
              button="true"
              onClick={() => toggleSection("prizepicks")}
              sx={{ ...listItemStyles, justifyContent: "space-between" }}
            >
              <ListItemText primary="Predictions" />
              {expanded === "prizepicks" ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {expanded === "prizepicks" &&
              prizepicksMenu.map((item) => (
                <ListItem
                  button="true"
                  key={item.name}
                  onClick={() => navigate(item.path)}
                  selected={location.pathname === item.path}
                  sx={{
                    ...listItemStyles,
                    backgroundColor:
                      location.pathname === item.path
                        ? colors.primary[300]
                        : "transparent",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItem>
              ))}

            {/* Performance Metrics Section */}
            <ListItem
              button="true"
              onClick={() => toggleSection("performance")}
              sx={{ ...listItemStyles, justifyContent: "space-between" }}
            >
              <ListItemText primary="Performance Metrics" />
              {expanded === "performance" ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {expanded === "performance" &&
              performanceMenu.map((item) => (
                <ListItem
                  button="true"
                  key={item.name}
                  onClick={() => navigate(item.path)}
                  selected={location.pathname === item.path}
                  sx={{
                    ...listItemStyles,
                    backgroundColor:
                      location.pathname === item.path
                        ? colors.primary[300]
                        : "transparent",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItem>
              ))}

            {/* Extra Section */}
            <ListItem
              button="true"
              onClick={() => toggleSection("extra")}
              sx={{ ...listItemStyles, justifyContent: "space-between" }}
            >
              <ListItemText primary="Extra" />
              {expanded === "extra" ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {expanded === "extra" &&
              extraMenu.map((item) => (
                <ListItem
                  button="true"
                  key={item.name}
                  onClick={() => navigate(item.path)}
                  selected={location.pathname === item.path}
                  sx={{
                    ...listItemStyles,
                    backgroundColor:
                      location.pathname === item.path
                        ? colors.primary[300]
                        : "transparent",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItem>
              ))}
          </List>
        </Box>

        {/* Logout Section */}
        <Box
          sx={{
            flexShrink: 0,
            textAlign: "center",
            backgroundColor: "#1E2846",
            borderRadius: "10px",
            padding: "10px",
            width: "100%",
          }}
        >
          <ListItem button="true" onClick={logout}>
            <ListItemIcon>
              <LogoutOutlined />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Box>
      </Box>
    );

    return isMobile ? (
      <Drawer
        open={isSidebarOpen}
        variant="temporary"
        onClose={handleSidebarToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: "300px",
            backgroundColor: "#374151",
            color: "#FFFFFF",
            boxSizing: "border-box",
          },
        }}
      >
        <SidebarContent />
      </Drawer>
    ) : (
      <Box
        gridArea="sidebar"
        sx={{
          overflow: "auto",
          width: "auto",
          height: "100%",
        }}
      >
        <SidebarContent />
      </Box>
    );
  }
);

export default SidebarComponent;
