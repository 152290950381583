import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  Divider,
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from "@mui/material";
import Grid2 from "@mui/material/Grid2"; // Correct use of Grid2
import { Edit } from "@mui/icons-material"; // Use Material UI edit icon
import VisaIcon from "../../../components/images/visa_icon.png"; // Import the Visa icon image
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css"; // Import cropper styles
import { useAuth } from "../../../components/context/AuthContext";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
const EditProfile = () => {
  
  const { googleData } = useAuth(); // Use the currentUser or googleData from AuthContext to get uid
  const [fullName, setFullName] = useState("Terry Phillips");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("1234"); // Only storing the last 4 digits
  // eslint-disable-next-line
  const [plan, setPlan] = useState("Hall of Fame");
  // eslint-disable-next-line
  const [billingDate, setBillingDate] = useState("01/01/2024");
  const [paymentUpdateMessage, setPaymentUpdateMessage] = useState(""); // For the payment update message
  // eslint-disable-next-line
  const [imageData, setImageData] = useState(null); // This will hold the cropped image
  const cropperRef = useRef(null); // Reference to the cropper instance

  // States for account deletion modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [customReason, setCustomReason] = useState("");
  const [showPlanOptions, setShowPlanOptions] = useState(false);
  const [editFullName, setEditFullName] = useState(false); // For toggling full name edit mode
  const [editPaymentMethod, setEditPaymentMethod] = useState(false); // For toggling payment method edit mode
 const {token} = useAuth();
  // File selection handler
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePhoto(URL.createObjectURL(file));
      setImageData(null); // Reset cropped image
    }
  };
  useEffect(() => {
    const fetchProfileImage = () => {
      try {
        const cachedUrl = sessionStorage.getItem("profileImageUrl");
        if (cachedUrl && !profilePhoto) {
          setImageData(cachedUrl);
        }
      } catch (error) {
        console.error("Error fetching profile image from sessionStorage:", error);
      }
    };
    fetchProfileImage();
  }, [profilePhoto]); // Only run when profilePhoto changes
  // Crop the image and save it
  const handleCrop = async () => {
    try {
      if (cropperRef.current) {
        const cropper = cropperRef.current.cropper; // Access the cropper instance directly
  
        if (cropper) {
          // Get the cropped canvas as a base64 string
          const croppedImage = cropper.getCroppedCanvas().toDataURL();
          
          setImageData(croppedImage); // Save the cropped image as the image data
          setProfilePhoto(null)
          console.log("Cropped Image:", croppedImage);
  
          // Upload the cropped image to Firebase
          await uploadCroppedImageToFirebase(croppedImage);
        } else {
          throw new Error("Cropper instance is not available.");
        }
      } else {
        throw new Error("Cropper ref is not defined.");
      }
    } catch (error) {
      console.error("Error in handleCrop:", error.message);
    }
  };
  // Upload cropped image to Firebase storage
  const uploadCroppedImageToFirebase = async (imageData) => {
    try {
        // Step 1: Validate `googleData` and `token`
        if (!googleData?.uid) {
            throw new Error("User ID is missing");
        }

        if (!token) {
            throw new Error("User authentication token is missing");
        }

        // Step 2: Get the user's UID
        const userId = googleData.uid;
        console.log("Authenticated User ID:", userId);

        // Step 3: Initialize Firebase Storage
        const storage = getStorage();
        const storageRef = ref(storage, `profilephoto/${userId}`); // Use userId in the file path

        // Step 4: Convert Base64 Image to Blob
        const base64Response = await fetch(imageData);
        const blob = await base64Response.blob();

        // Step 5: Upload Image to Firebase Storage
        console.log("Uploading image to Firebase Storage...");
        await uploadBytes(storageRef, blob);
        console.log("Profile picture uploaded successfully!");

        // Step 6: Get the Download URL
        const downloadURL = await getDownloadURL(storageRef);
        console.log("Image URL:", downloadURL);

        // Step 7: Call Backend API to Notify Profile Photo Change
        console.log("Notifying backend of the profile photo update...");
        const response = await fetch(
            "https://sport-prediction-app-fb7e125de9ad.herokuapp.com/changeprofilephoto",
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`, // Use token from AuthContext
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    profileImageUrl: downloadURL, // Send the download URL to the backend
                }),
            }
        );

        if (!response.ok) {
            const errorResponse = await response.json();
            console.error("Backend error response:", errorResponse);
            throw new Error("Failed to notify backend of profile photo change");
        }

        const result = await response.json();
        console.log("Backend response:", result);

       
        return result;

    } catch (error) {
        console.error("Error uploading profile image or notifying backend:", error.message);
        throw error;
    }
};
// eslint-disable-next-line
  const handleSaveChanges = () => {
    console.log("Changes Saved");
  };

  const handleCancelPlan = () => {
    console.log("Plan Canceled");
    setShowPlanOptions(false); // Close options after selection
  };

  const handleUpdatePlan = () => {
    console.log("Plan Updated");
    setShowPlanOptions(false); // Close options after selection
  };

  const openDeleteAccountModal = () => setOpenDeleteModal(true);
  const closeDeleteAccountModal = () => setOpenDeleteModal(false);

  const handleDeleteAccount = () => {
    console.log("Account Deleted");
    // Here, you can add the logic to delete the account
  };

  // Simulated function to fetch the last 4 digits from Stripe (mocking the real functionality)
  // eslint-disable-next-line
  const updatePaymentMethod = (newCardNumber) => {
    const lastFourDigits = newCardNumber.slice(-4); // Get the last 4 digits
    setPaymentMethod(lastFourDigits);
    setPaymentUpdateMessage(
      "Your payment method has been successfully updated! Last 4 digits: **** **** **** " +
        lastFourDigits
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: "30px",
        borderRadius: "10px",
        backgroundColor: "#1E2846",
        height: "100%", // Full height for the container
        display: "flex", // Ensure children can adjust height properly
        flexDirection: "column", // Arrange children vertically
        gap: "30px", // Added spacing between sections
      }}
    >
      <Typography variant="h4" sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
        Edit Profile
      </Typography>
      {/* Profile Photo Section */}
      <Box
        sx={{
          width: "100%",
          padding: "30px",
          borderRadius: "10px",
          backgroundColor: "#1E2846",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        {/* Profile Photo Section */}
        <Box sx={{ padding: "30px", backgroundColor: "#1E2846" }}>
      <Typography variant="h4" sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
        Edit Profile
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "30px" }}>
        <Typography sx={{ color: "#FFFFFF" }}>Profile Photo</Typography>

        <Card sx={{ width: 150, height: 150, borderRadius: "50%", backgroundColor: "#2D3B60", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {imageData ? (
  <img
    src={imageData}
    alt="Profile"
    style={{
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      objectFit: "cover",
    }}
  />
) : profilePhoto ? (
  <Cropper
    src={profilePhoto}
    style={{ height: 300, width: "100%" }}
    initialAspectRatio={1}
    aspectRatio={1}
    preview=".img-preview"
    guides={false}
    ref={cropperRef}
  />
) : (
  <Typography sx={{ color: "#9BB2D0", fontSize: "12px" }}>
    No Photo
  </Typography>
)}
        </Card>

        <input type="file" accept="image/*" onChange={handleFileChange} style={{ display: "none" }} id="file-input" />
        <label htmlFor="file-input">
          <Button variant="contained" component="span" sx={{ marginTop: "10px", backgroundColor: "#00DF82" }}>
            Change Photo
          </Button>
        </label>

        {profilePhoto && (
          <Button variant="contained" onClick={handleCrop} sx={{ marginTop: "10px" }}>
            Save Crop
          </Button>
        )}
      </Box>

      <Divider sx={{ marginY: "20px", backgroundColor: "#444D75" }} />
    </Box>
        <Divider sx={{ marginY: "20px", backgroundColor: "#444D75" }} />
      </Box>
      <Divider sx={{ marginY: "20px", backgroundColor: "#444D75" }} />{" "}
      {/* Divider between sections */}
      {/* Scrollable Content */}
      <Box sx={{ maxHeight: "500px", overflowY: "auto" }}>
        {/* Editable Full Name */}
        <Grid2 container spacing={2} alignItems="center">
          <Grid2
            item
            size={10}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Typography sx={{ color: "#FFFFFF" }}>Full Name</Typography>
          </Grid2>
          <Grid2
            item
            size={2}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {editFullName ? (
              <TextField
                label="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                fullWidth
                sx={{
                  backgroundColor: "#2D3B60",
                  borderRadius: "8px",
                  input: { color: "#FFFFFF" },
                  label: { color: "#9BB2D0" },
                }}
              />
            ) : (
              <Typography sx={{ color: "#9BB2D0" }}>{fullName}</Typography>
            )}
            <IconButton
              onClick={() => setEditFullName(!editFullName)}
              sx={{ marginLeft: 1 }}
            >
              <Edit sx={{ color: "#00DF82" }} />
            </IconButton>
          </Grid2>
        </Grid2>

        <Divider sx={{ marginY: "20px", backgroundColor: "#444D75" }} />

        {/* Username (Read-Only) */}
        <Grid2 container spacing={2} alignItems="center">
          <Grid2
            item
            size={10}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Typography sx={{ color: "#FFFFFF" }}>Username</Typography>
          </Grid2>
          <Grid2
            item
            size={2}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Typography sx={{ color: "#9BB2D0" }}>terry_phillips</Typography>
          </Grid2>
        </Grid2>

        <Divider sx={{ marginY: "20px", backgroundColor: "#444D75" }} />

        {/* Plan Section */}
        <Grid2 container spacing={2} alignItems="center">
          <Grid2
            item
            size={10}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Typography sx={{ color: "#FFFFFF" }}>Plan</Typography>
          </Grid2>
          <Grid2
            item
            size={2}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Typography sx={{ color: "#9BB2D0" }}>{plan}</Typography>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} alignItems="center">
          <Grid2
            item
            size={10}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Typography sx={{ color: "#FFFFFF" }}>Next Billing Date</Typography>
          </Grid2>
          <Grid2
            item
            size={2}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Typography sx={{ color: "#9BB2D0" }}>{billingDate}</Typography>
          </Grid2>
        </Grid2>

        <Divider sx={{ marginY: "20px", backgroundColor: "#444D75" }} />

        {/* Payment Method */}
        <Grid2 container spacing={2} alignItems="center">
          <Grid2
            item
            size={10}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Typography sx={{ color: "#FFFFFF" }}>Payment Method</Typography>
          </Grid2>
          <Grid2
            item
            size={2}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {editPaymentMethod ? (
              <TextField
                label="Card Number"
                value={paymentMethod}
                onChange={(e) => {
                  const value = e.target.value;
                  // Only update if the input contains digits and has a max length of 16
                  if (/^\d{0,16}$/.test(value)) {
                    setPaymentMethod(value);
                  }
                }}
                fullWidth
                sx={{
                  backgroundColor: "#2D3B60",
                  borderRadius: "8px",
                  input: { color: "#FFFFFF" },
                  label: { color: "#9BB2D0" },
                }}
              />
            ) : (
              <Typography sx={{ color: "#9BB2D0", marginRight: "10px" }}>
                <img
                  src={VisaIcon} // Using the Visa icon image path
                  alt="Visa Icon"
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    marginRight: "5px",
                    verticalAlign: "middle",
                  }}
                />
                {paymentMethod}
              </Typography>
            )}
            <IconButton
              onClick={() => setEditPaymentMethod(!editPaymentMethod)}
              sx={{ marginLeft: 1 }}
            >
              <Edit sx={{ color: "#00DF82" }} />
            </IconButton>
          </Grid2>
        </Grid2>

        {/* Show confirmation message */}
        {paymentUpdateMessage && (
          <Typography sx={{ color: "#00DF82", marginTop: "10px" }}>
            {paymentUpdateMessage}
          </Typography>
        )}

        <Divider sx={{ marginY: "20px", backgroundColor: "#444D75" }} />

        {/* Modify Plan */}
        <Grid2 container spacing={2} alignItems="center">
          <Grid2
            item
            size={10}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Typography sx={{ color: "#FFFFFF" }}>Modify Plan</Typography>
          </Grid2>
          <Grid2
            item
            size={2}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#00DF82",
                borderRadius: "10px",
                color: "#FFFFFF",
                borderColor: "#00DF82",
                fontWeight: "bold",
              }}
              onClick={() => setShowPlanOptions(!showPlanOptions)}
            >
              {showPlanOptions ? "Cancel Plan" : "Update Plan"}
            </Button>
          </Grid2>
        </Grid2>

        {showPlanOptions && (
          <Box
            sx={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
          >
            <Button
              variant="outlined"
              onClick={handleCancelPlan}
              sx={{
                backgroundColor: "#FF4E4E",
                borderRadius: "10px",
                color: "#FFFFFF",
                borderColor: "#FF4E4E",
                width: "48%",
                fontWeight: "bold",
              }}
            >
              Cancel Plan
            </Button>
            <Button
              variant="outlined"
              onClick={handleUpdatePlan}
              sx={{
                backgroundColor: "#00DF82",
                borderRadius: "10px",
                color: "#FFFFFF",
                borderColor: "#00DF82",
                width: "48%",
                fontWeight: "bold",
              }}
            >
              Update Plan
            </Button>
          </Box>
        )}

        <Divider sx={{ marginY: "20px", backgroundColor: "#444D75" }} />

        {/* Delete Account Button */}
        <Grid2
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid2 item size={8}>
            <Button
              variant="outlined"
              onClick={openDeleteAccountModal}
              sx={{
                backgroundColor: "#FF4E4E",
                borderRadius: "10px",
                color: "#FFFFFF",
                borderColor: "#FF4E4E",
                width: "100%",
                fontWeight: "bold",
              }}
            >
              Delete Account
            </Button>
          </Grid2>
        </Grid2>
      </Box>
      {/* Delete Account Modal */}
      <Modal open={openDeleteModal} onClose={closeDeleteAccountModal}>
        <Box
          sx={{
            width: "400px",
            margin: "auto",
            backgroundColor: "#2D3B60",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "#FFFFFF", marginBottom: "20px" }}
          >
            Are you sure you want to delete your account?
          </Typography>

          {/* Delete Reason */}
          <RadioGroup
            value={deleteReason}
            onChange={(e) => setDeleteReason(e.target.value)}
            sx={{ marginBottom: "10px" }}
          >
            <FormControlLabel
              value="No longer needed"
              control={<Radio />}
              label="No longer needed"
            />
            <FormControlLabel
              value="Found a better alternative"
              control={<Radio />}
              label="Found a better alternative"
            />
            <FormControlLabel value="Other" control={<Radio />} label="Other" />
          </RadioGroup>

          {/* Other reason if selected */}
          {deleteReason === "Other" && (
            <TextField
              label="Other Reason"
              value={customReason}
              onChange={(e) => setCustomReason(e.target.value)}
              fullWidth
              multiline
              maxRows={4}
              sx={{
                backgroundColor: "#2D3B60",
                borderRadius: "8px",
                marginBottom: "20px",
                input: { color: "#FFFFFF" },
                label: { color: "#9BB2D0" },
              }}
            />
          )}

          {/* Confirm Deletion */}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              onClick={handleDeleteAccount}
              sx={{ backgroundColor: "#FF4E4E", color: "#FFFFFF" }}
            >
              Confirm Deletion
            </Button>
            <Button
              variant="outlined"
              onClick={closeDeleteAccountModal}
              sx={{ backgroundColor: "#00DF82", color: "#FFFFFF" }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default EditProfile;
