import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../components/context/AuthContext";

// List of routes that require authentication
export const privateRoutes = [
  "/dashboard",
  "/usermanagement",
  "/prizepicks/projections",
  "/prizepicks/smartpicks",
  "/prizepicks/advanced",
  "/prizepicks/bots",
  "/model/performance",
  "/bot/performance",
  "/referral",
  "/faq",
  "/invoices",
  "/admin/dashboard",
  "/cancel",
  "/success",
  "/createuser",
  "/editprofile"
];

// PrivateRoute component to handle route protection
const PrivateRoute = ({ component: RouteComponent, requiresAdmin = false, requiredSubType = null }) => {
  const { currentUser, signupComplete, role, sub_status, sub_type, loading, checkingStatus } = useAuth();
  const location = useLocation();

  // Wait until loading and checkingStatus are both false before proceeding with checks
  if (loading || checkingStatus) {
    return null; // Optionally show a loading spinner here
  }

  // Redirect to login if not logged in and loading is complete
  if (!currentUser && !loading && !checkingStatus) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }


  // Redirect to signup if user hasn't completed signup and loading is complete
  if (!signupComplete && !loading && !checkingStatus) {
    return <Navigate to="/signup" state={{ from: location.pathname }} replace />;
  }

  // Redirect to subscriptions if user hasn't activated subscription and loading is complete
  if (!sub_status && !loading && !checkingStatus) {
    return <Navigate to="/subscriptions" state={{ from: location.pathname }} replace />;
  }

  // Redirect to dashboard if a specific subscription type is required but user doesn't match, and loading is complete
  if (requiredSubType && sub_type !== requiredSubType && !loading && !checkingStatus) {
    return <Navigate to="/dashboard" replace />;
  }

  // Redirect to user dashboard if admin access is required but user is not an admin, and loading is complete
  if (requiresAdmin && role !== "admin" && !loading && !checkingStatus) {
    return <Navigate to="/dashboard" replace />;
  }

  // If all checks pass, render the requested route component
  return <RouteComponent />;
};

export default PrivateRoute;
